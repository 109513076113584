/* ============= GENERALES ================== */
* {
    -moz-osx-font-smoothing: grayscale; 
    -webkit-font-smoothing: antialiased;
    font-family: 'Roboto', sans-serif;
    font-display: swap;
}
.bg {
    background-color: #E4E3E3 !important;
    width: 100%;
    height: 100%;
}

.margen-arriba {
    padding-top: 150px;
}

.off {
    display: none;
}

.on {
    display: block;
}

.title {
    
    background-color: #204051;
    width: 100%;
    font-size: 2em;
    color: #fafafa;
    text-align: center;
    padding: 10px 10px 10px 0px;
    margin-bottom: 30px;
}

.redondear-borde {
    border-radius: 50px;
}
.centrar-contenido {
    text-align: center;
}


/* =============  FIN GENERALES ================== */

/* ============= TARIFAS ================== */

.card-tarifa .card-title {
   
    font-size: 20px;
    padding: 10px;
    

}
.card-tarifa h5 {
  font-size: 14px;
}
.card-tarifa .img-izq {
  width: 100%;
  height: 100%;
}
.card-tarifa__body {
    text-align: center;
    
}
.card-tarifa__p {
   
    font-size: 30px;
    text-align: center;
}
.card-tarifa {
    
    background: #fafafa;
    height: auto;
    border: 1px solid rgba(0,0,0,.125);
    box-shadow: 2px 2px 5px -2px #666;
    margin-bottom: 45px;
    overflow: hidden;
}
/* ============= FIN TARIFAS ================== */

/* ============= HEADER =======================*/

@media screen and (max-width: 1200px) {
  
  .nav-transparent, .dropdown-menu {
    background-color: #E4E3E3;
    text-transform: uppercase;
    font-weight: 700 !important;
    color: #000;
    transition: .2s;
}
header {
  margin-top: 96px;
}
}

@media screen and (max-width: 720px) {
  .home__title {
    text-align: center;
    color: #000;
    text-transform: uppercase;
    font-size: 24px !important;
    margin-top: 10px;
}
.img-slider-header {
  width: 100%;
  height: 530px;
  object-fit: cover;
  object-position: right;
  overflow: hidden;
  margin-top: -55px;
}
.slick-dots li button {
  display: none;
}


}
.nav-transparent, .dropdown-menu {
    text-transform: uppercase;
    font-weight: 700 !important;
    color: #000;
    transition: .2s;
}
.dropdown-item.active, .dropdown-item:active {
  background-color: #3b6978 !important;
}
.dropdown-item {
  font-weight: 700 !important;
  color: #000;
}

.nav-color, .dropdown-menu {
  background-color:#E4E3E3 ;
  text-transform: uppercase;
  font-weight: 700 !important;
  color: #000;
  transition: .2s;
}

.dropdown-item:hover {
    background-color: #3b6978 !important;
}


.img-slider-header {
  width: 100%;
  height: 615px;
  object-fit: cover;
  overflow: hidden;
}

.text-slider {
  position: absolute;
  top: 522px;
  width: 100%;
  height: 100px;
  background: rgba(0,0,0,.8);
  color: #fafafa;
  text-align: center;
  font-size: 24px;
}
.logomenugrande {
    width:198px;
    margin-left: 10px;
    padding: 0px;
    transition: .2s;
}
.logomenuchico {
  width:140px;
  margin-left: 10px;
  padding: 0px;
  transition: .2s;
}

.itemmenu {
    padding-right: 25px;
    font-size: 18px;
}

/* ============= FIN HEADER =======================*/

/* ============= COMO LLEGAR =======================*/

.datos-ubi {
    padding: 20px 10px 0px 10px;
    text-align: center;
    
    width: 100%;
    height: auto;
    border-radius: 5%;
    background-color: #204051;
    color:#fafafa;
    font-size: 16px;
    
    margin-bottom: 80px;
    margin-left: auto;
    margin-right: auto;
    height: 400px;
    
}
.datos-ubi p {
  padding: 30px;
}


p .card-text{
    text-align: justify;
    padding:10px;
}

.card-text ul {
  text-align: left;
  list-style: square;
  
}

@media screen and (max-width: 994px) {
  .datos-ubi .social-circle li a {
    display: none !important;
  }
}
.datos-ubi .social-circle li a {
    display: inline-block;
    position: relative;
    margin: 0 15px 0 15px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    width: 40px;
    height: 40px;
    font-size: 20px;
    
  }

.datos-ubi ul li {
    display: inline-block;
    text-align: left;
    margin-left:5%; 
}

  iframe {
    border-radius: 5%;
    width: 100%;
    height: 400px;
    border:#204051 4px solid;
    border-radius: 5%;
    margin-bottom: 40px;
  }
  
/* ============= FIN COMO LLEGAR =======================*/

/* ============= CONTACTO =======================*/

.datos-contacto {
    padding-top: 20px;
    text-align: center;
    margin: 0px auto 80px auto;
    min-height: 400px;
    border-radius: 5%;
    background-color: #204051;
    color:#fff;
    padding: 20px;
    
}

.form-contacto {
    min-height: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 80px;
    border-radius: 5%;
    padding: 20px;
    background-color: #204051;
    color: #fafafa;
    font-weight: bold;
    font-size: 14px;
}

.form-contacto button[type="submit"] {
  display: block;
  margin: auto;
  color: #204051;
  background-color: #fafafa;
  border: 1px solid #000;
}
.form-contacto button[type="submit"]:hover {
  display: block;
  margin: auto;
  color: #fafafa;
  background-color: #204051;
  border: 1px solid #fff;
  font-weight: bold;
}

  /* footer social icons */
  .datos-contacto .social-network a.icoFacebook:hover {
    background-color:#3B6978;
  }
  
  .datos-contacto .social-network a.icoInsta:hover {
    background-color:#3B6978;
  }
  
  .datos-contacto .social-network a.icoYoutube:hover {
    background-color: #3B6978;
  }
  .datos-contacto .social-circle li a {
    display: inline-block;
    position: relative;
    margin: 0 auto 0 auto;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    width: 40px;
    height: 40px;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .datos-contacto ul.social-network li {
     display: list-item;
     text-align: left;
     padding-top: 10px;
     margin-left: 5%;
     font-family: 'Work Sans', sans-serif;
  }

/* ============= FIN CONTACTO =======================*/

/* ============= SECTION LABERINTO ======================== */

.info-laberinto {
    padding: 20px;
    text-align: center;
    width: 100%;
    height: auto;
    border-radius: 5%;
    background-color: #3b6978;
    color:#000;
    margin-bottom: 80px;
    
  }
  .contenido {
    margin-top: 50px;
    padding-bottom: 70px;
  }
  
  
  
  .labe-1 {
    text-align: center;
    font-size: 15px;
    line-height: 25px;
    box-shadow: 2px 2px 5px -2px #666;
    *animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }
  
  .labe-1 img {
    *width: 100%;
    height: 100%;
  }

/* FIN SECTION LABERINTO */


/* SECTION PREG FRECUENTES */
.preguntas {
   
    color: #000;
    margin-top: 50px;
    padding-bottom: 50px;
}

.collapse {
   color: #3b6978;
}

/* FIN SECTION PREG FRECUENTES */


/* SECTION CONTINGENTES */

  .contenido .pequeña-bajada {
    text-align: center;
    margin: 0px auto 0px auto;
  }
  .pequeña-bajada p {
    font-size: 17px;
    padding: 5px 30px;
    font-weight: 400;
  }
  .pequeña-bajada .exp-laberinto {
    font-weight: bold;
    font-size: 36px;
  }
/* FIN SECTION CONTINGENTES */



/* SECTION CARDS */

.home__title {
    text-align: center;
    color: #000;
    font-size: 44px;
    margin-top: 50px;
}
.card--section {
  min-height: 506px;
}
.card_ {
    
    margin-bottom: 20px;
    margin-top: 45px;
}

.card-body .btn, .modal-footer .btn-modal{
    background-color:#204051;
    color: #fff;
    border: 1px #000;
}

@media screen and (max-width: 1200px) {
  .centrado-novedad {
    text-align: center !important;
    display: flex;
    align-items: center;
    justify-content: center !important;
  }
  .card-body .container-btn {
    position: static !important;
    text-align: center;
  }
  .datos-contacto {
    padding-top: 20px;
    text-align: center;
    margin: 0px auto 80px auto;
    min-height: 400px;
    border-radius: 5%;
    background-color: #204051;
    color:#fff;
    padding: 15px;
    font-size: 14px;
    
  }
}
.card-body .container-btn {
    display: block;
    position: absolute;
    top: 445px;
    left: 100px;
    text-align: center;
   
}
.card-body .btn:hover, .modal-footer .btn-modal:hover{
    background-color:#3B6978;
    color: #fff;
    border: 1px #000;
   
}
.card-title {
   
    font-size: 20px;
    text-align: center;
}

.card-body {
    text-align: center;
}
.card_img {
    height:100px;
}
 
/* FIN SECTION CARDS */

/* SECTION LOGUITOS */

.principal {
    height:auto;
    width: 100%;
    background-color: #3b6978;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

}
.principal img {
    
    max-width: 120px;
    padding: 10px;
     
}
.loguitos {
    opacity: 0.8;
}
.loguitos:hover {
    opacity:1;
    cursor: pointer;
}

/* FIN SECTION LOGUITOS */

/* SECTION TURNERA */
.form_turnos {
    
    
    padding: 20px;
    background-color: #fff;
    
    color: #000;
    width: 600px;
    margin: 0px auto 0px auto;
    font-weight: bold;
  
}

.form_turnos input {
    border-color:#000;
    outline: 0 none;
}

/* FIN SECTION TURNERA */

/* SECTION FOOTER */
footer {
    background-color: #204051;
    height:auto;
    width: 100%;
    color: #fff;
    padding-top: 10px;
    text-align: center;
   
   
}
.col-md-3 ul {
  list-style: none;
  padding: 0;
}
footer img  {
    max-width: 250px !important;
    max-height: auto;
    padding: 10px;
}
.title_footer {
  
  font-size: 21px;
}


footer a {
    color: #fff;
    font-size: 14px;
    transition-duration: 0.2s; 
}

footer a:hover {
    color: #3b6978;
    text-decoration: none;
  }

.copy {
    font-size: 12px;
    padding: 10px;
    border-top: 1px solid #FFFFFF;
}

.copy span {
  opacity: 0.4;
  font-size: 12px;
}
.copy a {
  font-size: 12px;
}


/* footer social icons */

ul.social-network {
    list-style: none;
    display: inline;
    
    
    padding: 0;
  }
  
  ul.social-network li {
    display: inline;
    margin: 0 7px;
    
  }
  
  
  /* footer social icons */
  
  .social-network a.icoFacebook:hover {
    background-color:#3B6978;
  }
  
  .social-network a.icoInsta:hover {
    background-color: #3B6978;
  }
  
  .social-network a.icoYoutube:hover {
    background-color: #3B6978;
  }

  .social-network a.icoFacebook:hover i,
  .social-network a.icoInsta:hover i,
  .social-network a.icoYoutube:hover i{
    color: #fff;
  }
  
  .social-network a.socialIcon:hover,
  .socialHoverClass {
    color: #44BCDD;
  }
  
  .social-circle li a {
    display: inline-block;
    position: relative;
    margin: 0 auto 0 auto;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    width: 40px;
    height: 40px;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .social-circle li i {
    margin: 0;
    line-height: 40px;
    text-align: center;
  }
  
  .social-circle li a:hover i,
  .triggeredHover {
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -ms--transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
  }
  
  .social-circle i {
    color: #595959;
    -webkit-transition: all 0.8s;
    -moz-transition: all 0.8s;
    -o-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
  }
  
  .social-network a {
    background-color: #F9F9F9;
  }

/* FIN SECTION FOOTER*/

/* HORARIOS */
 .img-horarios {
   width: 100%;
   height: 400px;
   border: #204051 4px solid;
   border-radius: 5%;
   margin-bottom: 40px;
 }

 .proto-covid_horarios {
   font-weight: 300;
   border: 1px solid #fafafa;
   border-radius: 5%;
   width: 80%;
   padding: 5px;
   margin: 0px auto 10px auto;
 }

 .datos-horarios {
  padding: 20px 10px 10px 10px;
  text-align: center;
  
  width: 100%;
  height: auto;
  border-radius: 5%;
  background-color: #204051;
  color:#fafafa;
  font-size: 16px;
  
  margin-bottom: 80px;
  margin-left: auto;
  margin-right: auto;
  min-height: 400px;
  
}

/* FIN HORARIOS */

.btn-contingentes {
  background-color: #204051;
  color: #fff;
  border: 1px #000;
  padding: 15px;
  border-radius: 5px;
}

.btn-contingentes:hover {
  background-color: #204051;
  color: #fff;
  border: 1px #204051;
  padding: 15px;
  border-radius: 5px;
}


.sk-circle {
  margin-left: auto;
  margin-right: auto;
  width: 40px;
  height: 40px;
  position: relative;
}
.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.sk-circle .sk-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #fafafa;
  border-radius: 100%;
  -webkit-animation: slide-fwd-center 0.45s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-fwd-center 0.45s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
          transform: rotate(30deg); }
.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
          transform: rotate(60deg); }
.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg); }
.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
          transform: rotate(120deg); }
.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
          transform: rotate(150deg); }
.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg); }
.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
          transform: rotate(210deg); }
.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
          transform: rotate(240deg); }
.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg); }
.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
          transform: rotate(300deg); }
.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
          transform: rotate(330deg); }
.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s; }
.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s; }
.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s; }
.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; }
.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s; }
.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s; }
.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s; }
.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s; }
.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s; }
.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s; }
.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s; }

@-webkit-keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  } 40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  } 40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

/** ANIMACIONES */

@keyframes scale-up-center {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

/* FIN ANIMACIONES */
#contenedor-novedades .card-body {
  max-height: 300px;
  width: auto;
  max-width: 100%;
  height:300px;
}
#contenedor-novedades .card-img {
  max-height: 300px;
  width: auto;
  max-width: 100%;
  height: auto;
}

#contenedor-novedades .iframe-novedad {
  margin-bottom: 0;
  border: none;
  border-radius: 0;
  height: 300px;
 
}
#contenedor-novedades .iframe-novedad-sinvideo {
  width: 100%;
  margin-bottom: -9px;
}

.alerta {
  color: red;
  border: 1px solid red;
  padding: 20px;
  display: inline-block;
}

.boton-dashboard {
  color: #000;
  border: 3px solid #204051;
  padding: 20px;
  margin-bottom: 30px;
  font-weight: bold;
  display: block;
  background: transparent;
  width: 100%;
}
.boton-dashboard a:link,.boton-dashboard a:visited,.boton-dashboard a:active,.boton-dashboard a:hover  {
  text-decoration: none;
  color: #000;
}

.boton-dashboard:hover {
  background: #204051;
  transition: 0.5s;
  color: #fff !important;
  text-decoration: none;
}

.header_fijo         { overflow-y: auto; height: 350px;  }
.header_fijo thead th { position: sticky; top: 0; background: #343a40; }
/* Just common table stuff. Really. */
.header_fijo table  { border-collapse: collapse; width: 100%; text-align: center;}
.header_fijo .table td, .header_fijo .table th {
  border-top: 1px solid #666;
}
.marquee {
  font-size: 18px;
}
.ml-100 {
  margin-left: 100%;
}

.parpadea {
  animation-name: parpadeo;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  *color: rgb(248, 72, 94) !important;
  -webkit-animation-name:parpadeo;
  -webkit-animation-duration: 1.5s;
  animation-delay: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes parpadeo{
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

@-webkit-keyframes parpadeo {
  0% { opacity: 1.0; }
  50% { opacity: 0.0;  }
  100% { opacity: 1.0; }
}

@keyframes parpadeo {
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.desactiva-animacion {
  animation: none !important;
}
.no-hay-novedades {
  font-size: 20px;
}
.centrado-novedad {
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: left;
  
}